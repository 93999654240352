export const profileOptions = [
  {
    id: 100,
    label: "Option 1",
    value: "Option 1",
  },
  {
    id: 101,
    label: "Option 2",
    value: "Option 2",
  },
  {
    id: 102,
    label: "Option 3",
    value: "Option 3",
  },
  {
    id: 103,
    label: "Option 4",
    value: "Option 4",
  },
];
