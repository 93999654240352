export const neutral = {
  10: "#ffffff",
  100: "#E7E7EC",
  200: "#C4C4CF",
  300: "#A0A1B2",
  400: "#838494",
  500: "#505165",
  600: "#2A2A43",
  700: "#12143F",
};

export const blue = {
  100: "#6668A4",
  200: "#515397",
  300: "#3B3D8A",
  400: "#25287D",
  500: "#212471",
  600: "#1E2064",
  700: "#1A1C58",
  800: "#16184B",
};

export const peach = {
  100: "#F6BF9C",
  200: "#F5B892",
  300: "#F4B187",
  400: "#F3AA7D",
  500: "#E69E72",
  600: "#DA9367",
  700: "#CD875C",
  800: "#C17B50",
};
