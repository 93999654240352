export const deviceData = [
  {
    id: 1,
    platform: "iOS",
    device: "iPhone 7",
    os: "14.4",
    browser: "Safari",
    resolution: "375 x 667",
  },
  {
    id: 2,
    platform: "iOS",
    device: "iPhone 12 Mini",
    os: "14.1",
    browser: "Safari",
    resolution: "375 x 812",
  },
  {
    id: 3,
    platform: "iOS",
    device: "iPad Air",
    os: "10.3.2",
    browser: "Safari",
    resolution: "768 x 1024",
  },
  {
    id: 4,
    platform: "iOS",
    device: "iPad Pro 12.9",
    os: "14.0.1",
    browser: "Safari",
    resolution: "1024 x 1366",
  },
  {
    id: 5,
    platform: "Android",
    device: "Google Pixel 3",
    os: "Android 10",
    browser: "Chrome",
    resolution: "393 x 786",
  },
  {
    id: 6,
    platform: "Mac",
    device: "Macbook Pro 15",
    os: "11.1",
    browser: "Safari",
    resolution: "1680 x 1050",
  },
  {
    id: 7,
    platform: "Mac",
    device: "Macbook Pro 15",
    os: "11.1",
    browser: "Chrome",
    resolution: "1680 x 1050",
  },
  {
    id: 7,
    platform: "Mac",
    device: "Macbook Pro 15",
    os: "11.1",
    browser: "Firefox",
    resolution: "1680 x 1050",
  },
];
