export const neutrals = [
  {
    id: 100,
    name: "10",
    value: "#FFFFFF",
    textInverted: false,
  },
  {
    id: 200,
    name: "100",
    value: "#E7E7EC",
    textInverted: false,
  },
  {
    id: 100,
    name: "200",
    value: "#C4C4CF",
    textInverted: false,
  },
  {
    id: 100,
    name: "300",
    value: "#A0A1B2",
    textInverted: false,
  },
  {
    id: 100,
    name: "400",
    value: "#838494",
    textInverted: true,
  },
  {
    id: 100,
    name: "500",
    value: "#505165",
    textInverted: true,
  },
  {
    id: 100,
    name: "600",
    value: "#2A2A43",
    textInverted: true,
  },
  {
    id: 100,
    name: "700",
    value: "#12143F",
    textInverted: true,
  },
];

export const blue = [
  {
    id: 100,
    name: "100",
    value: "#6668A4",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 200,
    name: "200",
    value: "#515397",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 300,
    name: "300",
    value: "#3B3D8A",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 400,
    name: "400",
    value: "#25287D",
    textInverted: true,
    primary: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 500,
    name: "500",
    value: "#212471",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 600,
    name: "600",
    value: "#1E2064",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 700,
    name: "700",
    value: "#1A1C58",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 800,
    name: "800",
    value: "#16184B",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
];

export const peach = [
  {
    id: 100,
    name: "100",
    value: "#F6BF9C",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 200,
    name: "200",
    value: "#F5B892",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 300,
    name: "300",
    value: "#F4B187",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 400,
    name: "400",
    value: "#F3AA7D",
    textInverted: true,
    main: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 500,
    name: "500",
    value: "#E69E72",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 600,
    name: "600",
    value: "#DA9367",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 700,
    name: "700",
    value: "#CD875C",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
  {
    id: 800,
    name: "800",
    value: "#C17B50",
    textInverted: true,
    lightText: false,
    darkText: true,
  },
];
